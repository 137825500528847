import './App.css';
import {useEffect, useRef, useState} from "react";
import {Application, Loader as PIXILoader, Sprite, utils} from "pixi.js";
import Loading from 'react-fullscreen-loading';
import TWEEN from 'tween.js';


const bodyList = [

    {name: 'arm_L', coords: {x: 1186, y: 1152}, colors: ['color3', 'color4'], types: ['standart']},
    {name: 'forearm_L', coords: {x: 1210, y: 1573}, colors: ['color3', 'color4'], types: ['standart']},
    {name: 'hand_L', coords: {x: 1153, y: 1812}, colors: ['color3', 'color4'], types: ['weed_fat']},

    {name: 'leg_L', coords: {x: 1209, y: 1719}, colors: ['color3', 'color4'], types: ['standart']},
    {name: 'foreleg_L', coords: {x: 1240, y: 2194}, colors: ['color3', 'color4'], types: ['standart']},
    {name: 'foot_L', coords: {x: 1119, y: 2545}, colors: ['color3', 'color4'], types: ['standart']},

    {name: 'leg_R', coords: {x: 1423, y: 1717}, colors: ['color3', 'color4'], types: ['standart']},
    {name: 'foreleg_R', coords: {x: 1472, y: 2195}, colors: ['color3', 'color4'], types: ['standart']},
    {name: 'foot_R', coords: {x: 1324, y: 2564}, colors: ['color3', 'color4'], types: ['standart']},

    {name: 'underwear_R', coords: {x: 1420, y: 1730}, colors: [], types: ['standart']},
    {name: 'underwear_L', coords: {x: 1194, y: 1797}, colors: [], types: ['standart']},


    {name: 'body', coords: {x: 1069, y: 1080}, colors: ['color3', 'color4'], types: ['standart'], zIndex: 0},
    {name: 'neck', coords: {x: 1315, y: 884}, colors: ['color3', 'color4'], types: ['standart'], zIndex: 1},
    {name: 'head', coords: {x: 1041, y: 425}, colors: ['color3', 'color4'], types: ['standart'], zIndex: 5},

    {name: 'lips', coords: {x: 1242, y: 930}, colors: ['color3', 'color4'], types: ['form2'], zIndex: 7},
    {name: 'nose', coords: {x: 1262, y: 751}, colors: [], types: ['shape4'], zIndex: 7},
    {name: 'eyes', coords: {x: 1206, y: 712}, colors: [], types: ['shape_9'], zIndex: 7},
    {name: 'pupil', coords: {x: 1227, y: 739}, colors: ['grey'], types: [], zIndex: 7},
    {name: 'brows_shape7', coords: {x: 1182, y: 687}, colors: ['black'], types: [], zIndex: 7},
    {name: 'hair_messycurly', coords: {x: 862, y: -40}, colors: ['black'], types: [], zIndex: 7},

    {name: 'underwear', coords: {x: 1207, y: 1561}, colors: [], types: ['standart']},
    {name: 'underwear_mask', coords: {x: 1207, y: 1559}, colors: [], types: ['standart']},

    {name: 'arm_R', coords: {x: 1555, y: 1229}, colors: ['color3', 'color4'], types: ['standart'], zIndex: 0  },
    {name: 'forearm_R', coords: {x: 1567, y: 1563}, colors: ['color3', 'color4'], types: ['standart'], zIndex: 3  },
    {name: 'hand_R', coords: {x: 1505, y: 1871}, colors: ['color3', 'color4'], types: ['lying_thin'], zIndex: 10  },

];

const bodyTypes = [
    {name: 'standart'},
    {name: 'form2'},
    {name: 'lying_thin'},
    {name: 'weed_fat'}
];

const bodyColors = [
    {name: 'color3', color: '#AF8374'},
    {name: 'color4', color: '#F7BAA8'}
];

const dressItems = [
    {name: 'Watch', objects: ['accessories_hand_watchgrey']},
    {name: 'Glasses', objects: ['accessories_head_blackShadowGlasses']},
    {name: 'Shoes', objects: ['shoes_L_beigeConverse', 'shoes_R_beigeConverse']},
    {name: 'Airpods', objects: ['accessories_ear_whiteAirpods']},
    {name: 'PocketPants', objects: ['pantleg_upper_R_brownPocketPants',
            'pantleg_bottom_R_brownPocketPants', 'pantleg_upper_L_brownPocketPants',
            'pantleg_bottom_L_brownPocketPants', 'hips_upper_brownPocketPants']},
    {name: 'Torsowear', objects: [
        // 'sleeve_bottom_L_blackRollneck',
            'sleeve_L_blackRollneck',
            'sleeve_bottom_R_blackRollneck', 'sleeve_R_blackRollneck', 'torsowear_blackRollneck'
        ]},
    {name: 'BlackCross', objects: ['accessories_neck_blackCross']},
    {name: 'BlueParka', objects: [
        'outerwear_blueParka',
            'outersleeve_R_blueParka',
            'outersleeve_bottom_R_blueParka',
        ]},
]

const dressList = [
    {name: 'accessories_hand_watchgrey', coords: {x: 1561, y: 1787}, colors: [], types: [], zIndex: 10  },
    {name: 'accessories_head_blackShadowGlasses', coords: {x: 1053, y: 447}, colors: [], types: [], zIndex: 8  },
    {name: 'accessories_ear_whiteAirpods', coords: {x: 1512, y: 732}, colors: [], types: [], zIndex: 8  },

    {name: 'shoes_L_beigeConverse', coords: {x: 1076, y: 2291}, colors: [], types: ['standart'], zIndex: 3  },
    {name: 'shoes_R_beigeConverse', coords: {x: 1289, y: 2282}, colors: [], types: ['standart'], zIndex: 3  },

    {name: 'pantleg_upper_R_brownPocketPants', coords: {x: 1438, y: 1685}, colors: [], types: ['standart'], zIndex: 2  },
    {name: 'pantleg_bottom_R_brownPocketPants', coords: {x: 1494, y: 2213}, colors: [], types: ['standart'], zIndex: 2  },
    {name: 'pantleg_upper_L_brownPocketPants', coords: {x: 1210, y: 1717}, colors: [], types: ['standart'], zIndex: 2  },
    {name: 'pantleg_bottom_L_brownPocketPants', coords: {x: 1265, y: 2208}, colors: [], types: ['standart'], zIndex: 2  },
    {name: 'hips_upper_brownPocketPants', coords: {x: 1222, y: 1731}, colors: [], types: ['standart'], zIndex: 2  },

    {name: 'torsowear_blackRollneck', coords: {x: 1064, y: 981}, colors: [], types: ['standart'], zIndex: 3  },
    {name: 'sleeve_bottom_L_blackRollneck', coords: {x: 1171, y: 1536}, colors: [], types: ['standart'], zIndex: 3  },
    {name: 'sleeve_L_blackRollneck', coords: {x: 1144, y: 1138}, colors: [], types: ['standart'], zIndex: 3  },
    {name: 'sleeve_bottom_R_blackRollneck', coords: {x: 1516, y: 1537}, colors: [], types: ['standart'], zIndex: 3  },
    {name: 'sleeve_R_blackRollneck', coords: {x: 1492, y: 1123}, colors: [], types: ['standart'], zIndex: 3  },

    {name: 'accessories_neck_blackCross', coords: {x: 1256, y: 1124}, colors: [], types: ['standart'], zIndex: 4   },

    {name: 'outerwear_blueParka', coords: {x: 989, y: 892}, colors: [], types: ['standart'], zIndex: 4 },
    {name: 'outersleeve_bottom_R_blueParka', coords: {x: 1522, y: 1538}, colors: [], types: ['standart'], zIndex: 4  },
    {name: 'outersleeve_R_blueParka', coords: {x: 1498, y: 1121}, colors: [], types: ['standart'], zIndex: 4  },

];

const allNames = [
    'accessories_ear_whiteAirpods',
    'accessories_hand_watchgrey',
    'accessories_head_blackShadowGlasses',
    'accessories_neck_blackCross_standart',
    'arm_L_standart_color3',
    'arm_L_standart_color4',
    'arm_R_standart_color3',
    'arm_R_standart_color4',
    'body_standart_color3',
    'body_standart_color4',
    'brows_shape7_black',
    'eyes_shape_9',
    'foot_L_standart_color3',
    'foot_L_standart_color4',
    'foot_R_standart_color3',
    'foot_R_standart_color4',
    'forearm_L_standart_color3',
    'forearm_L_standart_color4',
    'forearm_R_standart_color3',
    'forearm_R_standart_color4',
    'foreleg_L_standart_color3',
    'foreleg_L_standart_color4',
    'foreleg_R_standart_color3',
    'foreleg_R_standart_color4',
    'hair_messycurly_black',
    'hand_L_weed_fat_color3',
    'hand_L_weed_fat_color4',
    'hand_R_lying_thin_color3',
    'hand_R_lying_thin_color4',
    'head_standart_color3',
    'head_standart_color4',
    'hips_bottom_brownPocketPants_standart',
    'hips_upper_brownPocketPants_standart',
    'leg_L_standart_color3',
    'leg_L_standart_color4',
    'leg_R_standart_color3',
    'leg_R_standart_color4',
    'lips_form2_color3',
    'lips_form2_color4',
    'neck_standart_color3',
    'neck_standart_color4',
    'nose_shape4',
    'outersleeve_bottom_L_blueParka_standart',
    'outersleeve_bottom_R_blueParka_standart',
    'outersleeve_L_blueParka_standart',
    'outersleeve_R_blueParka_standart',
    'outerwear_blueParka_standart',
    'pantleg_bottom_L_brownPocketPants_standart',
    'pantleg_bottom_R_brownPocketPants_standart',
    'pantleg_upper_L_brownPocketPants_standart',
    'pantleg_upper_R_brownPocketPants_standart',
    'pupil_grey',
    'shoes_L_beigeConverse_standart',
    'shoes_R_beigeConverse_standart',
    'sleeve_bottom_L_blackRollneck_standart',
    'sleeve_bottom_R_blackRollneck_standart',
    'sleeve_L_blackRollneck_standart',
    'sleeve_R_blackRollneck_standart',
    'torsowear_blackRollneck_standart',
    'underwear_L_standart',
    'underwear_mask_standart',
    'underwear_R_standart',
    'underwear_standart',
];

const headAnimationParams = {
    dirX: -1,
    maxX: 7,
    minX: -10,
    speed: 1,
    elements: ['head', 'lips', 'nose', 'eyes', 'pupil', 'brows_shape7', 'hair_messycurly',
        'accessories_head_blackShadowGlasses', 'accessories_ear_whiteAirpods'],
}



function App() {
    const appRef = useRef(null);
    const appEl = useRef(null);
    const loader = PIXILoader.shared;
    const sprites = useRef({});

    const [colorName, setColorName] = useState('color3');
    const [bodyType, setBodyType] = useState('standart');
    const [dressActive, setDressActive] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const getSpriteName = (bodyElement) => {
        const type = bodyElement.types.includes(bodyType) ? bodyType : bodyElement.types[0];
        const color = bodyElement.colors.includes(colorName) ? colorName : bodyElement.colors[0];
        return [bodyElement.name, type, color].filter(el => el).join('_');
    }

    const buildBody = (app, resources) => {
        bodyList.forEach(bodyElement => {
            const spriteName = getSpriteName(bodyElement);
            if (!resources[spriteName]) return;
            const sprite = new Sprite(resources[spriteName].texture);
            sprite.x = bodyElement.coords.x; sprite.y = bodyElement.coords.y;
            if (typeof bodyElement.zIndex !== 'undefined') {
                sprite.zIndex = bodyElement.zIndex;
            }
            sprites.current[bodyElement.name] = sprite;
            app.stage.addChild(sprite);
        });
    }

    const isActiveColor = (color) => {
        return color.name === colorName;
    };

    const changeColor = () => {
        Object.keys(sprites.current).forEach(name => {
            const bodyElement = bodyList.find(el => el.name === name);
            if (!bodyElement) return;
            if (bodyElement.colors.includes(colorName)) {
                const spriteName = getSpriteName(bodyElement);
                if (loader.resources[spriteName]) {
                    sprites.current[name].texture = loader.resources[spriteName].texture;
                }
            }
        });
    };

    let headDelta = 0;

    const createAnimations = () => {
        const eyeCoors = {x: 0, prevX: 0};
        const eyeTween1 = new TWEEN.Tween(eyeCoors)
            .to({x: 20}, 1000)
            .onStart(() => {
                eyeCoors.x = 0;
            })
            .onUpdate(() => {
                sprites.current['pupil'].x += (eyeCoors.x - eyeCoors.prevX);
                eyeCoors.prevX = eyeCoors.x;
            })
            //.start()
        const eyeTween2 = new TWEEN.Tween(eyeCoors)
            .to({x: -17}, 2000)
            .onStart(() => {
                eyeCoors.x = 0;
            })
            .onUpdate(() => {
                sprites.current['pupil'].x += (eyeCoors.x - eyeCoors.prevX);
                eyeCoors.prevX = eyeCoors.x;
            })
            .onComplete(() => {
                eyeTween1.start();
            })
        // const eyeTween3 = new TWEEN.Tween(eyeCoors)
        //     .to({x: 10}, 2000)
        //     .onStart(() => {
        //         eyeCoors.x = 0;
        //     })
        //     .onUpdate(() => {
        //         sprites.current['pupil'].x += (eyeCoors.x - eyeCoors.prevX);
        //         eyeCoors.prevX = eyeCoors.x;
        //     })
        //     .onComplete(() => {
        //         eyeTween1.start();
        //     })
        eyeTween1.chain(eyeTween2);
        // eyeTween2.chain(eyeTween3);
        eyeTween1.delay(1000).start();
        // eyeTween2.start();
    };

    const animate = (delta) => {
        // console.log(delta)

        TWEEN.update();

        let headDeltaFrame = headAnimationParams.dirX * headAnimationParams.speed * delta;
        headDelta += headDeltaFrame;
        if (headDelta < headAnimationParams.minX) {
            headDelta = headAnimationParams.minX;
            headDeltaFrame = 0;
            headAnimationParams.dirX *= -1;
        }
        if (headDelta > headAnimationParams.maxX) {
            headDelta = headAnimationParams.maxX;
            headDeltaFrame = 0;
            headAnimationParams.dirX *= -1;
        }

        headAnimationParams.elements.forEach((name) => {
            if (sprites.current[name]) {
                sprites.current[name].x += headDeltaFrame;
            }
        });
    }

    const isActiveDress = (dressItem) => {
        return dressActive[dressItem.name];
    };

    const setDress = (dressItem) => {
        dressItem.objects.forEach((dressName) => {
            const dress = dressList.find(el => el.name === dressName);
            if (!dress) return;
            const spriteName = getSpriteName(dress);
            const sp = sprites.current;
            if (!loader.resources[spriteName]) return;
            if (sp[dress.name]) {
                sp[dress.name].destroy();
                sp[dress.name] = null;
                setDressActive({...dressActive, ...{[dressItem.name]: false}});
                // dressActive[dress.name] = false;
            } else {
                const sprite = new Sprite(loader.resources[spriteName].texture);
                sprite.x = dress.coords.x; sprite.y = dress.coords.y;
                if (typeof dress.zIndex !== 'undefined') {
                    sprite.zIndex = dress.zIndex;
                }
                sp[dress.name] = sprite;
                appRef.current.stage.addChild(sprite);
                setDressActive({...dressActive, ...{[dressItem.name]: true}});
            }
        });
    }

    useEffect(() => {
        appRef.current = new Application({
            width: 3000, height: 3000,
            backgroundColor: 0xFFFFFF,
            antialias: true
        });
        appRef.current.stage.sortableChildren = true;
        appRef.current.ticker.add(animate);

        allNames.forEach(name => {
            loader.add(name, `pngs/${name}.png`);
        });
        appEl.current.appendChild(appRef.current.view);

        return () => {
            loader.reset();
            loader.destroy();
            appRef.current.view.remove();
            appRef.current.destroy();
            utils.destroyTextureCache();
        }
    }, [loader]);

    useEffect(() => {
        loader.load((_loader, resources) => {
            buildBody(appRef.current, resources);
            setIsLoading(false);
            createAnimations();
        });
    }, [appRef, loader]);

    useEffect(() => {
        if (isLoading) return;
        changeColor();
    }, [colorName])

  return (
    <div className="App" ref={appEl}>
        <Loading loading={isLoading} background="#ffffff" loaderColor="#3227b9" />
        <ul className={"App-colors"}>
            {bodyColors.map(color => {
                return <li
                    className={"App-colors__item " + (isActiveColor(color) ? 'App-colors__item--active' : '')}
                    style={{backgroundColor: color.color}}
                    key={color.name}
                    onClick={(e) => { e.preventDefault(); setColorName(color.name) }}
                />
            })}
        </ul>
        <ul className={"App-dress-list"}>
            {dressItems.map(dress => {
                return <li
                    className={"App-dress__item " + (isActiveDress(dress) ? 'App-dress__item--active' : '')}
                    key={dress.name}
                    onClick={(e) => { e.preventDefault(); setDress(dress) }}
                >{dress.name}</li>
            })}
        </ul>
    </div>
  );
}

export default App;
